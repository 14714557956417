import { string, shape, bool } from 'prop-types';
import React from 'react';

import { InternalExternalLink } from '@/components/Link/InternalExternalLink';

import styles from './Card.module.scss';

const Card = ({
  data: {
    label,
    labelColorText,
    labelBackground,
    textBackground,
    heading,
    tagline,
    event,
    variant,
  },
}) => {
  const { eventInfo } = event;
  return (
    <div className={styles.wrapper}>
      {label && (
        <div
          className={styles.label}
          style={{
            background: labelBackground.value,
            color: labelColorText && labelColorText.value,
          }}
        >
          {label}
        </div>
      )}
      <div className={styles.card}>
        <div
          className={styles.overlay}
          style={{ background: textBackground && textBackground.value }}
        />
        <h2 className={styles.title}>{heading}</h2>
        {variant !== 'event' && <p className={styles.text}>{tagline}</p>}
        {variant === 'event' && (
          <div className={styles.shortNews}>
            {eventInfo?.dateEvent && (
              <p className={styles.text}>{eventInfo?.dateEvent}</p>
            )}
            {eventInfo?.description && (
              <p className={styles.text}>{eventInfo?.description}</p>
            )}
            {eventInfo?.showEventLink && (
              <InternalExternalLink
                href={eventInfo.urlEvent ?? '/'}
                className={styles.text}
              >
                {eventInfo.eventLinkName ?? 'Read more'}
              </InternalExternalLink>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

Card.propTypes = {
  data: shape({
    label: string,
    labelBackground: shape({}),
    textBackground: shape({}),
    heading: string,
    tagline: string,
    variant: string,
    event: shape({}),
  }),
};

Card.defaultProps = {
  data: {
    label: '',
    labelColorText: {},
    labelBackground: {},
    textBackground: {},
    heading: '',
    tagline: '',
    variant: '',
    event: {
      eventInfo: {
        dateEvent: '',
        description: '',
        urlEvent: '/',
        showEventLink: true,
        eventLinkName: '',
      },
    },
  },
};

export default Card;
