import IconDownloadPdf from '@/components/assets/IconDownloadPdf/IconDownloadPdf';
import IconReports from '@/components/assets/IconReports/IconReports';
import {
  IconAttractingIinspiringAndInvestingInPeople,
  IconCreatingSustainableHomesAndCommunities,
  IconEnvironmentallyConsiderateAndEfficientOperations,
  IconKeepingPeopleSafe,
  IconPuttingCustomersAtTheHeartOfWhatWeDo,
  IconSustainableAndResponsibleSourcing,
} from '@/components/assets/SustainabilityPillarsIcons/SustainabilityPillarsIcons';

import IconCallback from '../../assets/IconCallback/IconCallback';
import IconSchedule from '../../assets/IconSchedule/IconSchedule';
import IconTour from '../../assets/IconTour/IconTour';
import IconWalkthrough from '../../assets/IconWalkthrough/IconWalkthrough';

const iconVarian = (icon) => {
  return {
    virtualTour: <IconTour />,
    callBack: <IconCallback />,
    scheduleViewing: <IconSchedule />,
    walkthrough: <IconWalkthrough />,
    DownloadPdf: <IconDownloadPdf />,
    reportsPresentations: <IconReports />,
    puttingCustomersAtTheHeartOfWhatWeDo: (
      <IconPuttingCustomersAtTheHeartOfWhatWeDo color="#ffffff" />
    ),
    attractingInspiringAndInvestingInPeople: (
      <IconAttractingIinspiringAndInvestingInPeople color="#ffffff" />
    ),
    keepingPeopleSafe: <IconKeepingPeopleSafe color="#ffffff" />,
    creatingSustainableHomesAndCommunities: (
      <IconCreatingSustainableHomesAndCommunities color="#ffffff" />
    ),
    environmentallyConsiderateAndEfficientOperations: (
      <IconEnvironmentallyConsiderateAndEfficientOperations color="#ffffff" />
    ),
    sustainableAndResponsibleSourcing: (
      <IconSustainableAndResponsibleSourcing color="#ffffff" />
    ),
  }[icon];
};
export default iconVarian;
