import React from "react";

const IconTour = ({color}) => {
  return (
    <svg
      width="35"
      height="31"
      viewBox="0 0 35 31"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.2322 20.6863C15.2322 25.7892 19.4105 29.9263 24.5639 29.9263C29.7158 29.9263 33.8942 25.7892 33.8942 20.6863C33.8942 15.5806 29.7158 11.4435 24.5639 11.4435C19.4105 11.4435 15.2322 15.5806 15.2322 20.6863Z"
        stroke={color}
        strokeWidth="1.4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M27.6735 20.9933H24.5639V16.0648"
        stroke={color}
        strokeWidth="1.4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.64447 5.16044C2.64447 7.61615 4.65361 9.60329 7.13293 9.60329C9.61226 9.60329 11.6214 7.61615 11.6214 5.16044C11.6214 2.70329 9.61226 0.714722 7.13293 0.714722C4.65361 0.714722 2.64447 2.70329 2.64447 5.16044Z"
        stroke={color}
        strokeWidth="1.4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.9202 15.1254C11.3957 11.9583 7.56777 10.6154 4.37305 12.1269C2.14036 13.1812 0.719689 15.4126 0.721131 17.8612V22.3069H3.92738L4.56777 29.9254H9.69805L10.3384 22.3069H12.2394"
        stroke={color}
        strokeWidth="1.4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default IconTour;


IconTour.defaultProps={
  color:'#ffffff'
}