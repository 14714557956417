import React from "react";

const IconCallback = () => {
  return (
    <svg
      width="38"
      height="36"
      viewBox="0 0 38 36"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M27.5079 22.268C27.3909 22.0754 27.0759 21.9548 26.6043 21.7226C26.1309 21.4904 23.8071 20.3528 23.3769 20.198C22.9449 20.0432 22.6281 19.9622 22.3131 20.432C21.9981 20.9 21.0927 21.9548 20.8173 22.2626C20.5401 22.5722 20.2665 22.6154 19.7949 22.3814C18.4017 21.8324 17.1147 21.044 15.9915 20.0558C14.9583 19.1108 14.0745 18.0218 13.3653 16.8158C13.0827 16.3478 13.3347 16.094 13.5723 15.86C14.0673 15.4082 14.4687 14.8646 14.7531 14.258C14.8809 13.9952 14.8665 13.6856 14.7135 13.4372C14.5983 13.205 13.6533 10.8974 13.2609 9.96315C12.8145 8.89755 10.8309 8.86335 10.0317 9.72735C7.51171 12.4616 8.09311 15.5594 10.3143 18.4808C10.5501 18.794 13.5813 23.681 18.3819 25.5584C23.1843 27.434 23.1843 26.8058 24.0501 26.7302C26.5395 26.5052 28.2225 23.456 27.5133 22.2734"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M29.5646 13.5036L29.5628 5.40356H36.7646"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M29.5628 5.40359C36.14 11.4408 36.968 21.5154 31.469 28.5426C25.9646 35.5716 15.9836 37.1844 8.5478 32.2506C1.112 27.3168 -1.282 17.4924 3.056 9.69299C7.3958 1.88999 17.0024 -1.26001 25.1168 2.45339"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default IconCallback;
