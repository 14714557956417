import cx from 'classnames';
import React from 'react';

import {
  textClasses,
  fontClasses,
  weightClasses,
  proseSizeClasses,
  alignClasses,
} from '../../classes';

export const Text = ({
  children,
  as = 'p',
  color,
  align = 'left',
  size = 'base',
  className,
  font = 'sans',
  weight = 'normal',
}) => {
  const Element = as;

  return (
    <Element
      className={cx(
        'break-words prose prose-a:hover:underline font-normal !leading-snug prose-strong:font-semibold prose-strong:text-current prose-a:text-current max-w-[80ch]',
        alignClasses[align],
        proseSizeClasses[size],
        fontClasses[font],
        weightClasses[weight],
        color ? textClasses[color] : 'text-current',
        className,
      )}
    >
      {children}
    </Element>
  );
};

export const TextMemo = React.memo(Text);
