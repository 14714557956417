import dynamic from 'next/dynamic';
import { Suspense } from 'react';

const LazyYoutube = dynamic(() => import('./LazyYoutube'), {
  suspense: true,
});

export default function Youtube(props) {
  return (
    <Suspense fallback={``}>
      <LazyYoutube {...props} />
    </Suspense>
  );
}
