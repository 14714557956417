import React from 'react';

const IconReports = () => {
  return (
    <svg width="34" height="33" viewBox="0 0 34 33" fill="none">
      <path
        d="M15.0001 31.3332H3.00008C2.26275 31.3332 1.66675 30.7372 1.66675 29.9998V1.99984C1.66675 1.26384 2.26275 0.666504 3.00008 0.666504H20.7334C21.0841 0.669171 21.4187 0.809171 21.6667 1.05584L26.6094 5.99984C26.8601 6.24917 27.0001 6.58917 27.0001 6.9425V11.3332"
        stroke="white"
        strokeWidth="1.4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7 18.5363L10.9387 14.5976C11.3267 14.2096 11.9347 14.1496 12.392 14.4536L14.2507 15.6923C14.7173 16.0043 15.3427 15.9323 15.728 15.5216L19.3787 11.627"
        stroke="white"
        strokeWidth="1.4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.00537 8.6665V21.9998H12.3387"
        stroke="white"
        strokeWidth="1.4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M32.3386 23.333C32.3386 18.9157 28.7573 15.333 24.3386 15.333C19.9213 15.333 16.3386 18.9157 16.3386 23.333C16.3386 27.7503 19.9213 31.333 24.3386 31.333C28.7573 31.333 32.3386 27.7503 32.3386 23.333Z"
        stroke="white"
        strokeWidth="1.4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M20.3386 23.3332H28.3386"
        stroke="white"
        strokeWidth="1.4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M28.3386 23.333L25.3386 26.333"
        stroke="white"
        strokeWidth="1.4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M28.3386 23.333L25.3386 20.333"
        stroke="white"
        strokeWidth="1.4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default IconReports;
