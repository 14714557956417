import React from "react";

const IconTour = () => {
  return (
    <svg
      width="37"
      height="36"
      viewBox="0 0 37 36"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M31.6042 16.2027C33.519 16.2027 35.073 14.6362 35.073 12.7027C35.073 10.7707 33.519 9.20267 31.6042 9.20267C29.6895 9.20267 28.1355 10.7707 28.1355 12.7027C28.1355 14.6362 29.6895 16.2027 31.6042 16.2027Z"
        stroke="white"
        strokeWidth="1.4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M31.6042 18.9249C29.0497 18.9249 26.9792 21.014 26.9792 23.5916V27.4805H29.2917L30.0626 34.4805H33.1459L33.9167 27.4805H36.2292V23.5916C36.2292 21.014 34.1588 18.9249 31.6042 18.9249Z"
        stroke="white"
        strokeWidth="1.4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.39587 11.9249V21.2582C5.39587 21.6891 5.74121 22.036 6.16671 22.036H21.5834C22.0089 22.036 22.3542 21.6891 22.3542 21.2582V11.9249"
        stroke="white"
        strokeWidth="1.4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M0.770874 11.9249L12.8545 1.16667C13.4372 0.647112 14.3129 0.647112 14.8956 1.16667L24.6667 9.86845"
        stroke="white"
        strokeWidth="1.4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.5625 22.036V15.8138C11.5625 14.5258 12.5985 13.4804 13.875 13.4804C15.1515 13.4804 16.1875 14.5258 16.1875 15.8138V22.036"
        stroke="white"
        strokeWidth="1.4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default IconTour;
