import React from "react";

const IconTour = () => {
  return (
    <svg
      width="36"
      height="38"
      viewBox="0 0 36 38"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14.7771 34.0642C6.79862 33.5655 0.742371 31.14 0.742371 28.2203C0.742371 26.0783 4.008 24.1991 8.89456 23.1556"
        stroke="white"
        strokeWidth="1.4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M26.7899 23.1709C31.6468 24.2174 34.8827 26.0862 34.8827 28.2178C34.8827 31.3231 28.0308 33.8703 19.2968 34.1315"
        stroke="white"
        strokeWidth="1.4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.156 29.2272L14.7764 34.0648L9.64789 37.1033"
        stroke="white"
        strokeWidth="1.4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.8125 8.90625C20.0673 8.90625 21.8945 7.08047 21.8945 4.82422C21.8945 2.56945 20.0673 0.742188 17.8125 0.742188C15.5577 0.742188 13.7305 2.56945 13.7305 4.82422C13.7305 7.08047 15.5577 8.90625 17.8125 8.90625Z"
        stroke="white"
        strokeWidth="1.4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M23.0078 16.7734C23.0078 13.9027 20.6818 11.5781 17.8125 11.5781C14.9432 11.5781 12.6172 13.9027 12.6172 16.7734V21.2266H14.967L15.5859 28.6484H20.0391L20.658 21.2266H23.0078V16.7734Z"
        stroke="white"
        strokeWidth="1.4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default IconTour;
