import React from 'react';

const IconDownloadPdf = () => {
  return (
    <svg width="28" height="33" viewBox="0 0 28 33" fill="none">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M26.6094 5.99984C26.8601 6.2505 27.0001 6.58784 27.0001 6.9425V29.9998C27.0001 30.7358 26.4027 31.3332 25.6667 31.3332H3.00008C2.26408 31.3332 1.66675 30.7358 1.66675 29.9998V1.99984C1.66675 1.2625 2.26408 0.666504 3.00008 0.666504H20.7241C21.0774 0.666504 21.4161 0.805171 21.6667 1.0545L26.6094 5.99984Z"
        stroke="white"
        strokeWidth="1.4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.81439 9.18716C5.67972 7.31916 8.39438 5.60183 9.10372 7.51783C10.3184 10.8072 12.2717 19.7058 7.86505 25.7538C6.63572 27.4392 3.98372 25.8432 5.41039 24.0872C9.54505 19.2098 15.6317 16.4192 22.0264 16.4685C24.2504 16.5525 24.1357 19.7112 22.1464 19.4685C15.7091 18.6272 10.0371 14.8232 6.81439 9.18716Z"
        stroke="white"
        strokeWidth="1.4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default IconDownloadPdf;
