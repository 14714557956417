import classnames from 'classnames';
import dynamic from 'next/dynamic';
import { useRouter } from 'next/router';
import { string, arrayOf, shape, oneOf } from 'prop-types';
import React, { useState, useRef, useEffect } from 'react';
import { Suspense } from 'react';

import LegacyLinkClickAction from '@/components/ClickAction/legacy/LegacyUrlClickAction';
import { InternalExternalLink } from '@/components/Link/InternalExternalLink';
import Modal from '@/components/Modal/Modal';
import { PortableText } from '@/components/PortableText/PortableText';
import PrimaryButtonTR from '@/components/PrimaryButton/Transparent';
import { ResponsiveImage } from '@/components/images/ResponsiveImage';
import {
  scheduleAction,
  registerInterestAction,
  requestCallbackAction,
  virtualTourAction,
  downloadsAction,
} from '@/helpers/gtmActions/gtmAcions';

import { useInViewRatio } from '../../../hooks/useInView';
import { Text } from '../../module/Text';
import Card from './components/Card';
import iconVarian from './data';

import styles from './Hero.module.scss';

const RegisterInterest = dynamic(() => import('@/components/RegisterInterest'), {
  suspense: true,
});

const Hero = React.memo((props) => {
  const router = useRouter();
  const document = props.document;
  switch (document._type) {
    case 'development':
      props = extractDevelopmentProps(document, props);
      break;
    case 'property':
      props = extractPropertyProps(document, props);
      break;
  }

  const wrapperRef = useRef(null);
  const inViewRatio = useInViewRatio({
    elementRef: wrapperRef,
    assumeRatio: 1,
  });

  const titleEffectStyle = {
    willChange: 'opacity, transform',
    transition: 'opacity .001s, transform .001s',
    opacity: inViewRatio,
    transform: `translateY(calc(${(1 / inViewRatio) * 100}px - 100px))`,
    transformOrigin: 'center center',
    filter: `blur(${50 * (1 - inViewRatio)}px)`,
  };

  const imageEffectStyle = {
    willChange: 'transform',
    transition: 'transform .001s',
    transform: `scale3d(${1 + (1 - inViewRatio) / 15}, ${
      1 + (1 - inViewRatio) / 15
    }, 1)`,
    transformOrigin: 'top center',
  };

  const {
    heading,
    headingBlock,
    backgroundImage,
    tagline,
    variant,
    gradientColor,
    gradientSize,
    labelBackground,
    label,
    labelColorText,
    textBackground,
    iconsVariant,
    event,
    page,
    pageType,
    slug,
    development,
    type,
    pardotUrl,
    propertyId,
    propertyTitle,
  } = props;

  const [iframeUrl, setIframeUrl] = useState('');
  const [isRegisterFlowOpen, setIsRegisterFlowOpen] = useState(false);
  const [isShowModal, setIsShowModal] = useState(false);
  const iframeRef = useRef(null);

  const developmentData = development;

  const overlayClasses = classnames(styles.overlay, {
    [styles[`${gradientSize}Gradient`]]: gradientSize,
  });

  const heroClasses = classnames(styles.wrapper, {
    [styles[`${variant}Hero`]]: variant,
  });

  const onBlur = () => {
    if (document.activeElement === iframeRef.current) {
      virtualTourAction('Self-tour', 'Hero', page);
    }
  };

  useEffect(() => {
    window.addEventListener('blur', onBlur);
    return () => {
      window.removeEventListener('blur', onBlur);
    };
  });

  const handleIconClick = (e, action, category, gtmType, url) => {
    if (action !== 'link' && action !== 'download') {
      e.preventDefault();
    }
    if (action === 'lightbox') {
      setIframeUrl(url);
      setIsShowModal(true);
    }

    if (action === 'registerInterest') {
      setIsRegisterFlowOpen(true);
    }

    const location = 'Hero';
    switch (category) {
      case 'ScheduleViewing':
        return scheduleAction(gtmType, location, page);
      case 'RegisterInterest':
        return registerInterestAction(location, page);
      case 'RequestCallback':
        return requestCallbackAction(location, page);
      case 'VirtualTour':
        return virtualTourAction(type, location, page);
      case 'Downloads':
        return downloadsAction(pageType, page, url);

      default:
        return null;
    }
  };

  const handleClick = () => setIsShowModal(false);

  const handleRegisterFlowClose = () => {
    setIsRegisterFlowOpen(false);
    const scrollY = window.scrollY;
    router.push(location.href.split('#')[0], null, { shallow: true });
    window.scrollTo(0, scrollY);
  };

  useEffect(() => {
    if (!router?.events) return;

    const handleRouteChange = (url) => {
      const hash = url.split('#')[1];
      if (!hash?.trim().length) return;
      if (hash === 'registerinterest') setIsRegisterFlowOpen(true);
    };

    router.events.on('hashChangeStart', handleRouteChange);
    handleRouteChange(location.href);
    return () => router.events.off('hashChangeStart', handleRouteChange);
  }, []);

  return (
    <section className={heroClasses} ref={wrapperRef} key={heading || tagline}>
      <div
        className={overlayClasses}
        style={{
          background: `linear-gradient(180deg, rgba(0, 0, 0, 0.0001) 0%, ${gradientColor.value} 68.53%)`,
        }}
      />
      {backgroundImage?.asset && (
        <div className={styles.imageWrapper} style={imageEffectStyle}>
          <ResponsiveImage
            asset={backgroundImage.asset}
            hotspot={backgroundImage.hotspot}
            alt={heading}
            layout="fill"
            className={styles.image}
            priority
            roundSize={40}
          />
        </div>
      )}

      {variant === 'primary' ? (
        <div className={styles.primaryContent} style={titleEffectStyle}>
          {headingBlock ? (
            <Text
              as="div"
              color="white"
              className={classnames(
                styles.markdown,
                'prose-headings:text-white prose-headings:font-bold',
              )}
            >
              <PortableText
                content={headingBlock}
                components={{
                  block: {
                    normal: ({ children }) => <h1>{children}</h1>,
                  },
                }}
              />
            </Text>
          ) : (
            <h1 className={styles.title}>{heading}</h1>
          )}
          <p className={styles.text}>{tagline}</p>
        </div>
      ) : (
        <>
          <div className={styles.card} style={titleEffectStyle}>
            <Card
              data={{
                label,
                labelColorText,
                labelBackground,
                textBackground,
                heading,
                tagline,
                event,
                variant,
              }}
            />
          </div>

          {variant === 'secondary' && !!iconsVariant.length && (
            <div className={styles.icons}>
              {iconsVariant.map((el) => {
                if (!el.clickAction) {
                  return (
                    <div className={styles.icon} key={el.variant}>
                      {(el.iconAction === 'link' || el.iconAction === 'download') &&
                      el.url ? (
                        <InternalExternalLink
                          href={el.url}
                          className={styles.iconLink}
                          target={el.iconAction === 'download' ? '_blank' : ''}
                          rel={
                            el.iconAction === 'download' ? 'noreferrer noopener' : ''
                          }
                          onClick={(e) =>
                            handleIconClick(
                              e,
                              el?.iconAction,
                              el?.gtmFormat?.gtmCategory,
                              el?.gtmFormat?.gtmType,
                              el?.url,
                            )
                          }
                        >
                          <div className={styles.iconWrap}>
                            {iconVarian(el.variant)}
                          </div>
                          <div className={styles.label}>{el.titleIcon}</div>
                        </InternalExternalLink>
                      ) : (
                        <div
                          className={styles.iconLink}
                          onClick={(e) =>
                            handleIconClick(
                              e,
                              el?.iconAction,
                              el?.gtmFormat?.gtmCategory,
                              el?.gtmFormat?.gtmType,
                              el?.url,
                            )
                          }
                        >
                          <div className={styles.iconWrap}>
                            {iconVarian(el.variant)}
                          </div>
                          <div className={styles.label}>{el.titleIcon}</div>
                        </div>
                      )}
                    </div>
                  );
                }
                return (
                  <div className={styles.icon} key={el.variant}>
                    <LegacyLinkClickAction action={el.clickAction} section="Hero">
                      <div className={styles.iconLink}>
                        <div className={styles.iconWrap}>
                          {iconVarian(el.variant)}
                        </div>
                        <div className={styles.label}>{el.titleIcon}</div>
                      </div>
                    </LegacyLinkClickAction>
                  </div>
                );
              })}
            </div>
          )}
          {variant === 'event' && (
            <InternalExternalLink link={event?.btnUrl} className={styles.ctaBtn}>
              <PrimaryButtonTR as="span" text={event.ctaBtn} />
            </InternalExternalLink>
          )}

          {isShowModal && (
            <Modal
              handleClick={handleClick}
              data={{
                lightboxTitle: heading,
                lightboxSubtitle: tagline,
                lightboxContact: true,
              }}
              actionData={{
                developmentReference: { _ref: development._id },
                propertyReference: { _ref: propertyId },
              }}
              useVideoBox
            >
              <iframe id="iframe" src={iframeUrl} title={heading} ref={iframeRef} />
            </Modal>
          )}
        </>
      )}

      {developmentData &&
        !!Object.keys(developmentData).length &&
        isRegisterFlowOpen && (
          <Suspense fallback={`loading…`}>
            <RegisterInterest
              developmentData={{
                ...developmentData,
                property: { title: propertyTitle },
              }}
              slug={slug}
              onCloseClick={handleRegisterFlowClose}
              pageType={type}
              pageTitle={page}
              pardotUrl={pardotUrl}
            />
          </Suspense>
        )}
    </section>
  );
});

Hero.propTypes = {
  heading: string,
  headingBlock: string,
  backgroundImage: shape({}),
  labelBackground: shape({}),
  labelColorText: shape({}),
  gradientColor: shape({}),
  gradientSize: oneOf(['small', 'medium', 'large']),
  tagline: string,
  variant: oneOf(['primary', 'secondary', 'simple', 'event']),
  label: string,
  textBackground: shape({}),
  iconsVariant: arrayOf(shape({})),
  event: shape({ ctaBtn: string, btnUrl: string, eventInfo: shape({}) }),
  page: string,
  pageType: string,
  development: shape({}),
  properties: arrayOf(shape({})),
  pardotUrl: string,
  slug: string,
  type: string,
  propertyTitle: string,
  propertyId: string,
};

Hero.defaultProps = {
  heading: '',
  headingBlock: '',
  backgroundImage: {},
  labelBackground: {},
  labelColorText: {},
  gradientColor: {},
  gradientSize: 'medium',
  tagline: '',
  variant: 'secondary',
  label: '',
  textBackground: {},
  iconsVariant: [{ url: '/', titleIcon: '', variant: '' }],
  event: { eventInfo: {}, ctaBtn: '', btnUrl: '/' },
  page: '',
  pageType: '',
  development: {},
  properties: [],
  pardotUrl: '',
  slug: '',
  type: '',
  propertyTitle: '',
  propertyId: '',
};

const extractDevelopmentProps = (development, props = {}) => {
  return {
    development,
    title: development.title,
    theme: development.theme,
    place: development.place,
    heading: development.title ?? props.heading,
    headingBlock: props.headingBlock,
    backgroundImage: development.image ?? props.backgroundImage,
    labelBackground: props.labelBackground, // Move to development theme?
    labelColorText: props.labelColorText, // Move to development theme?
    gradientColor: props.gradientColor, // Move to development theme?
    gradientSize: props.gradientSize,
    tagline: development.place ?? props.tagline,
    variant: props.variant,
    label: development.status ?? props.label,
    textBackground: props.textBackground,
    iconsVariant: props.iconsVariant,
    event: props.event,
    properties: development.properties ?? [],
    pardotUrl: development?.pardotUrl ?? '',
  };
};

const extractPropertyProps = (property, props = {}) => {
  return {
    development: property.development,
    heading: property.title ?? props.heading,
    headingBlock: props.headingBlock,
    backgroundImage: property.image ?? props.backgroundImage,
    labelBackground: props.labelBackground,
    labelColorText: props.labelColorText,
    gradientColor: props.gradientColor ?? '#000000',
    gradientSize: props.gradientSize ?? 'medium',
    tagline: property.place ?? props.tagline,
    variant: props.variant ?? 'secondary',
    label: props.label,
    textBackground: property.theme ?? props.textBackground,
    iconsVariant: props.iconsVariant,
    event: props.event,
    pardotUrl: property?.development?.pardotUrl ?? '',
    propertyTitle: property.title,
    propertyId: property._id,
  };
};

export default Hero;

Hero.displayName = 'Hero';
