export const IconAttractingIinspiringAndInvestingInPeople = ({
  color = '#000000',
}) => {
  return (
    <svg
      viewBox="0 0 48 48"
      height="48"
      width="48"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g transform="matrix(2,0,0,2,0,0)">
        <path
          d="M12.486.774l1.028,2.714h2a.49.49,0,0,1,.345.855l-1.736,1.21.962,2.209a.524.524,0,0,1-.746.655L12.015,7.11,9.69,8.417a.524.524,0,0,1-.746-.655l.962-2.209L8.17,4.343a.489.489,0,0,1,.344-.855h2L11.543.774A.531.531,0,0,1,12.486.774Z"
          fill="none"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M7.507,8.927l-.9.626.962,2.209a.524.524,0,0,1-.746.655L4.5,11.11,2.176,12.417a.524.524,0,0,1-.746-.655l.962-2.209L.656,8.343A.489.489,0,0,1,1,7.488H3l1.03-2.714a.531.531,0,0,1,.943,0L6,7.488H7.007"
          fill="none"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M16.507,8.927l.9.626-.961,2.209a.524.524,0,0,0,.746.655l2.324-1.307,2.324,1.307a.524.524,0,0,0,.747-.655l-.962-2.209,1.736-1.21a.489.489,0,0,0-.344-.855h-2L19.986,4.774a.531.531,0,0,0-.943,0L18.015,7.488H17.007"
          fill="none"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M8.793 16.238 A3.250 3.250 0 1 0 15.293 16.238 A3.250 3.250 0 1 0 8.793 16.238 Z"
          fill="none"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M16.493,23.488a5.367,5.367,0,0,0-9,0"
          fill="none"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </svg>
  );
};

export const IconCreatingSustainableHomesAndCommunities = ({
  color = '#000000',
}) => {
  return (
    <svg
      viewBox="0 0 48 48"
      height="48"
      width="48"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g transform="matrix(2,0,0,2,0,0)">
        <path
          d="M14.919,14.117c-1.7,1.048-2.46,2.684.387,4.455.292,3.343,1.915,3.52,3.8,2.363,1.732-1.061,3.5-4.625,3.416-7.255a.5.5,0,0,0-.642-.464C19.283,13.985,16.9,12.9,14.919,14.117Z"
          fill="none"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M11.434,23.192s4.461-6.661,8.27-7.524"
          fill="none"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M18.478 2.5L18.478 11"
          fill="none"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M3.478 23.5L3.478 2.5"
          fill="none"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M1.478 2.5L20.478 2.5"
          fill="none"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M1.478 23.5L8.478 23.5"
          fill="none"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M1.478 16.5L10.478 16.5"
          fill="none"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M1.478 9.5L20.478 9.5"
          fill="none"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M17.478,2.5V1a.5.5,0,0,0-.5-.5h-3a.5.5,0,0,0-.5.5V2.5"
          fill="none"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </svg>
  );
};

export const IconEnvironmentallyConsiderateAndEfficientOperations = ({
  color = '#000000',
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 48 48"
      height="48"
      width="48"
    >
      <g transform="matrix(2,0,0,2,0,0)">
        <path
          d="M9,12.514V10.922c0-1.737.48-2.916,1.224-3.344"
          fill="none"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M10.225,7.578S7.587.213,16.618.509C14.155,2.5,16.107,8.6,10.225,7.578Z"
          fill="none"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M7.629,4.455c-.923-.8-3.24-2.267-7.011-1.118,3.233,2.108,2.549,7.212,8.719,5.415"
          fill="none"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M22.1,1.639,14.15,13.724,7.333,16a.873.873,0,0,0-.595,1.021h0a.873.873,0,0,0,.907.684l8.409-.49a2,2,0,0,0,1.459-.764L23.5,8.8"
          fill="none"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <line
          x1="8.993"
          y1="20"
          x2="8.993"
          y2="23.5"
          fill="none"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <line
          x1="0.5"
          y1="21"
          x2="6"
          y2="21"
          fill="none"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <line
          x1="23.379"
          y1="21"
          x2="11.879"
          y2="21"
          fill="none"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </svg>
  );
};

export const IconKeepingPeopleSafe = ({ color = '#000000' }) => {
  return (
    <svg
      viewBox="0 0 48 48"
      height="48"
      width="48"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g transform="matrix(2,0,0,2,0,0)">
        <path
          d="M17.5,9a.5.5,0,0,0-.5-.5H14.5V6a.5.5,0,0,0-.5-.5H10a.5.5,0,0,0-.5.5V8.5H7a.5.5,0,0,0-.5.5v4a.5.5,0,0,0,.5.5H9.5V16a.5.5,0,0,0,.5.5h4a.5.5,0,0,0,.5-.5V13.5H17a.5.5,0,0,0,.5-.5Z"
          fill="none"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M1.5,3.775v7.637A12.311,12.311,0,0,0,9.719,22.88l1.121.414a3.365,3.365,0,0,0,2.32,0l1.121-.414A12.311,12.311,0,0,0,22.5,11.412V3.775a1.533,1.533,0,0,0-.934-1.406A24.256,24.256,0,0,0,12,.5,24.256,24.256,0,0,0,2.434,2.369,1.533,1.533,0,0,0,1.5,3.775Z"
          fill="none"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </svg>
  );
};

export const IconPuttingCustomersAtTheHeartOfWhatWeDo = ({ color = '#000000' }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 48 48"
      height="48"
      width="48"
    >
      <g transform="matrix(2,0,0,2,0,0)">
        <path
          d="M6.249,21.961A11.5,11.5,0,0,1,15.136.933"
          fill="none"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M17.751,2.039A11.5,11.5,0,0,1,8.973,23.1"
          fill="none"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <polyline
          points="2.68 22.916 6.25 21.959 5.293 18.389"
          fill="none"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <polyline
          points="21.32 1.084 17.75 2.041 18.707 5.611"
          fill="none"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <circle
          cx="12.188"
          cy="8.341"
          r="3.398"
          fill="none"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M17.474,16.966a5.564,5.564,0,0,0-10.455,0"
          fill="none"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M9.8,5.922c1.159,2.154,3.733,2.419,5.783,2.419"
          fill="none"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </svg>
  );
};

export const IconSustainableAndResponsibleSourcing = ({ color = '#000000' }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 48 48"
      height="48"
      width="48"
    >
      <g transform="matrix(2,0,0,2,0,0)">
        <path
          d="M8.481,8.849a1.5,1.5,0,0,0-.231.8v6"
          fill="none"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M23.25,13.85v6.8a1,1,0,0,1-1,1H20.833"
          fill="none"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M8.25,15.65v-6h-3a4.5,4.5,0,0,0-4.5,4.5v6a1.5,1.5,0,0,0,1.5,1.5H5.386"
          fill="none"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <circle
          cx="7.5"
          cy="20.9"
          r="2.25"
          fill="none"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <circle
          cx="18.715"
          cy="20.9"
          r="2.25"
          fill="none"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <line
          x1="9.612"
          y1="21.65"
          x2="16.595"
          y2="21.65"
          fill="none"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M.75,15.65H4.689a1,1,0,0,0,1-1v-5"
          fill="none"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M16.98,12.85a6,6,0,1,0-6-6A6,6,0,0,0,16.98,12.85Z"
          fill="none"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M19.654,5.11,16.749,8.984a.752.752,0,0,1-1.131.08l-1.5-1.5"
          fill="none"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <line
          x1="8.25"
          y1="15.65"
          x2="8.25"
          y2="18.788"
          fill="none"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </svg>
  );
};
