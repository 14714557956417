import React from 'react';

import IconArrow, { COLOUR } from '../../assets/IconArrow/IconArrow';

import styles from './PrimaryButtonTR.module.scss';

export default function PrimaryButtonTR({ text, as = 'span' }) {
  const Element = as;
  return (
    <Element className={styles.button}>
      <span className={styles.button__name}>{text}</span>
      <div className={styles.wrapIcon}>
        <IconArrow color={COLOUR.WHITE} />
      </div>
    </Element>
  );
}
